<template>
  <div class="locationsWrapper">
    <locations />
  </div>
</template>

<script>
import Locations from '../components/Locations/Locations'

export default {
  components: {
    locations: Locations
  },

  data() {
    return {}
  },

  watch: {},

  created() { },

  beforeDestry() { },

  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
