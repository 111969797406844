<template>
  <div class="location-component">
    <div class="searchInput-wrapper">
      <div class="field has-addons">
        <div class="control">
          <input
            type="text"
            v-model="searchTerm"
            v-on:keyup.13="doSearch"
            class="input is-expanded"
            placeholder="Search locations"
          >
        </div>
        <div class="control">
          <a
            class="button"
            @click="doSearch"
          >
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
    </div>

    <hr class="separator">

    <transition-group
      tag="div"
      v-if="locations && locations.length > 0"
      class="locationList columns is-multiline"
      name="locationList"
    >
      <div
        class="column is-half"
        v-for="location in locations"
        :key="'l'+location.Id"
      >
        <location-card
          :location="location"
          class="has-background-grey-lighter"
        ></location-card>
      </div>
    </transition-group>
    <hr class="separator">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isLoading"
        class="has-padding has-text-centered"
      >
        <img
          src="@/assets/img/s2m-loader-big.gif"
          alt="loader"
        >
      </div>
      <div
        v-else-if="!isLoading && !reachedEnd"
        class="has-padding has-text-centered"
      >
        <a
          @click="loadMore"
          class="button is-primary"
        >Load more locations</a>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import location from '../../providers/location'
import LocationCard from './LocationCard.vue'

export default {
  components: {
    LocationCard
  },

  data() {
    return {
      bottom: false,
      isLoading: true,
      itemsPerPage: 12,
      locations: [],
      page: 1,
      reachedEnd: false,
      searchTerm: ''
    }
  },
  computed: mapGetters({
    isInitiated: 'getIsInitiated',
    isOnline: 'getConnectionStatus',
    latitude: 'getLatitude',
    longitude: 'getLongitude'
  }),

  watch: {
  },

  created() {
    this.searchTerm = ''

    this.getLatLon()
  },

  beforeDestry() {
    window.removeEventListener('scroll', this.updateOnlineStatus)
  },

  methods: {
    loadMore() {
      this.page = this.page + 1
      this.getLocations()
    },

    doSearch() {
      this.reachedEnd = false
      this.page = 1
      this.locations = []
      this.$store.commit('setLocations', [])
      this.getLocations()
    },

    getLocations() {
      this.getLocationsFromApi(this.latitude, this.longitude, this.searchTerm.trim(), this.page, this.itemsPerPage)
    },

    getLocationsFromApi(latitude, longitude, searchTerm, page, itemsPerPage) {
      if (!this.reachedEnd) {
        this.isLoading = true

        location.methods
          .getLocationsFromApi(latitude, longitude, searchTerm, page, itemsPerPage)
          .then(data => {
            if (data) {
              let locations = []

              if (data.MorePages === 0) {
                this.reachedEnd = true
              }

              if (page > 1) {
                locations = this.locations
              }

              for (let i = 0; i < data.Results.length; i++) {
                locations.push(data.Results[i])
                // locations.push({
                //   Id: data.Results[i].Id,
                //   Name: data.Results[i].Name,
                //   Image: data.Results[i].Image,
                //   Address: data.Results[i].Address,
                //   City: data.Results[i].City,
                //   Latitude: data.Results[i].Latitude,
                //   Longitude: data.Results[i].Longitude,
                //   Distance: data.Results[i].Distance,
                //   NrOfCheckIns: data.Results[i].NrOfCheckIns,
                //   Email: data.Results[i].Email,
                //   Phone: data.Results[i].Phone,
                //   Meetingtypes: data.Results[i].Meetingtypes
                // })
              }

              this.$store.commit('setLocations', locations)
              this.locations = locations

              this.isLoading = false
            }
          })
          .catch(e => {
            this.isLoading = false
          })
      }
    },

    getLatLon() {
      let self = this
      let geoOptions = {
        enableHighAccuracy: false,
        maximumAge: 300000,
        timeout: 10000
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.processPosition,
          function() {
            self.getLocations()
          },
          geoOptions
        )
      } else {
        this.getLocations()
      }
    },

    processPosition(position) {
      this.$store.commit('setLatitude', position.coords.latitude)
      this.$store.commit('setLongitude', position.coords.longitude)

      this.getLocations()
    }
  }
}
</script>

<style lang="scss" scoped>
// @import "../../assets/styles/template.scss";
.searchInput-wrapper {
  display: block;

  .field {
    &.has-addons {
      .control:not(:last-child) {
        width: 91%;

        @media screen and (min-width: $tablet) {
          width: 46%;
        }
      }
    }
  }
}

.locationList {
  @media screen and (max-width: 480px) {
    &.columns {
      .column {
        width: 100%;
      }
    }
  }
}

.location-enter-active,
.location-leave-active {
  transition: all 1s;
}
.location-enter,
.location-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
