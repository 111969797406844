<template>
  <div
    class="box box-location p-0 is-shadowless is-clickable"
    :class="classList"
    itemscope
    itemtype="https://schema.org/LocalBusiness"
    :id="'loc_' + location.Id"
    @click="locationClicked()"
  >
    <div class="box-image">
      <figure class="image is-4by3">
        <img
          src="/img/spacer4_3.gif"
          :style="{ backgroundImage: `url('${defaultImage}')` }"
          class="has-background-grey-light"
        />
      </figure>
    </div>

    <div class="card-content">
      <div class="topPart">
        <a
          class="title is-5 mb-4 is-inline-block"
          itemprop="name"
        >
          {{ location.Name }}</a>
        <div
          class="content content-adres"
          itemprop="address"
          itemscope
          itemtype="https://schema.org/PostalAddress"
        >
          <div class="icon-text-address icon-text is-flex-wrap-nowrap pr-4">
            <span class="icon has-text-primary-dark">
              <font-awesome-icon :icon="['fas', 'map-marker']" />
            </span>
            <span itemprop="streetAddress">
              {{ location.Address }}
              <span itemprop="addressLocality">{{ location.City }}</span>
              <meta
                itemprop="postalCode"
                :content="location.Zipcode"
              />
            </span>
          </div>
          <span
            v-if="location.PhoneNumber"
            class="icon-text-phone icon-text is-flex-wrap-nowrap"
          >
            <span class="icon has-text-primary-dark">
              <font-awesome-icon :icon="['fas', 'phone-alt']" />
            </span>
            <span itemprop="telephone">{{ location.PhoneNumber }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="upsellItems.length"
        class="bottomPart pt-5 is-flex"
      >
        <UpsellTemplate
          :upsellItems="upsellItems"
          class="upsList mr-5"
        />
        <!-- <ReviewScore
          v-if="location.ReviewScore !== 0"
          class="is-small has-score-bottom"
          :score="location.ReviewScore"
          :reviewCount="location.ReviewCount"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import UpsellTemplate from '@/components/UI/UpsellTemplate'
// import ReviewScore from '@/components/UI/ReviewScore.vue'

export default {
  name: 'LocationCard',

  components: {
    UpsellTemplate,
    // ReviewScore
  },

  props: {
    /**
     * The location object from the Available Location api
     */
    location: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      swiperIsReady: false,
      swiperRenderCounter: 0,
      swiperOptions: {
        initialSlide: 0,
        spaceBetween: 2,
        slidesPerView: 1,
        pagination: false,
        preloadImages: true,
        lazyLoading: true,
        watchSlidesProgress: false,
        lazy: {
          loadPrevNext: false,
          checkInView: true,
        },
        navigation: {
          nextEl: '.swiper-custom-button-next',
          prevEl: '.swiper-custom-button-prev',
        },
      },
      mapPointerHovering: false,
      isHorizontal: true,
      isCompact: false,
    }
  },

  computed: {
    defaultImage() {
      let _image = this.location.Images.find(i => i.IsDefault && !i.IsLogo)
      if (typeof _image !== 'undefined') { return _image.UrlMedium }
      return this.location.Image !== ''
        ? this.$options.filters.getLocationImage(
          this.location.Image,
          this.location.Id,
          320
        )
        : ''
    },

    upsellItems() {
      if (!this.location.Features || !this.location.Features.length) {
        return []
      }

      let output =
        this.location.Features.filter(
          (t) => t.Language === this.$i18n.locale
        ) || this.location.Features.filter((t) => t.Language === 'en')

      return Array.from(output, (f) => f.Body)
    },

    /**
     * The dynamic classes applied to main element
     */
    classList() {
      return {
        'is-hovering': this.mapPointerHovering,
        'is-horizontal': this.isHorizontal,
        'is-compact': this.isCompact,
        'has-background-success-light': false,
      }
    },
  },

  mounted() {
    window.addEventListener('resize', this.checkCardSize)
    this.checkCardSize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkCardSize)
  },

  methods: {
    ...mapMutations(['setSelectedLocation']),

    checkCardSize() {
      let elSize = 1000
      if (typeof this.$el !== 'undefined') {
        elSize = this.$el.offsetWidth
      }

      if (elSize) {
        if (elSize < 550 && this.isHorizontal) {
          this.isHorizontal = false
        } else if (elSize >= 550 && !this.isHorizontal) {
          this.isHorizontal = true
        }

        if (elSize < 500 && !this.isCompact) {
          this.isCompact = true
        } else if (elSize >= 500 && this.isCompact) {
          this.isCompact = false
        }
      }
      this.swiperRenderCounter++
    },

    locationClicked() {
      // this.setSelectedLocation(this.location.Id)
      this.$router.push({
        name: 'NetworkLocation',
        params: {
          locationId: this.location.Id
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box-location {
  height: 100%;
  .swiper-slide {
    width: 100% !important;
    object-fit: cover;
    object-position: center;
  }

  &.is-horizontal {
    display: flex;

    .no-text-wrap {
      white-space: nowrap;
    }

    .box-image {
      max-width: 280px;
      width: 100%;
      height: 100%;
      border-radius: $radius;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      .topPart {
        flex-grow: 0;
      }

      .bottomPart {
        flex-grow: 1;
        .upsList {
          flex-grow: 1;
          flex-shrink: 1;
        }

        ::v-deep .component-reviewScore {
          align-self: flex-end;
        }
      }
    }
  }

  .icon-text {
    span:nth-child(2) {
      flex: 1 1;
    }
  }

  &:not(.is-horizontal) {
    .card-content {
      .card-image {
        width: 100%;
      }

      .bottomPart {
        align-items: flex-end;
        .upsList {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }
  }

  &.is-compact {
    // .content-adres {
    //   .icon-text {
    //     display: block;
    //   }
    // }
    .upsList {
      display: none;
    }
  }
}
</style>
